<template>
    <div class="change-data-page">
        <div class="container-fluid">
            
            <h1>Dodawanie nowego użytkownika</h1>
    
            <div class="form-container">
                <form method="post" @submit.prevent="beforeSubmit" action="">
                    <inputSelect v-model="form.userType" name="userType" label="Typ" 
                        :options="userTypeOptions" :error="validation.firstError('form.userType')" />
                    
                    <inputText v-model="form.name" name="name" label="Imię" :error="validation.firstError('form.name')" />
                    <inputText v-model="form.surname" name="surname" label="Nazwisko"  :error="validation.firstError('form.surname')"  />
                    
                    <inputEmail v-model="form.email" name="email" label="E-mail" :showSpinner="validation.isValidating('form.email')" 
                           :error="validation.firstError('form.email')" />
                    <inputText v-model="form.phone" name="phone" label="Telefon" :error="validation.firstError('form.phone')" />

                    <dismissAlert ref="errorAlert" />

                    <div class="text-center">
                        <buttonLoader type="submit" class="btnLong" :isLoading="inProgress" label="Dodaj" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

import { USER_typeAdmin, USER_typeDriver, USER_typeCustomer  } from "@/constants/constants.js";

export default {
    name: "adminUserAdd",
    data: () => ({
        inProgress: false,
        successMessage: '',
        userTypeOptions:  [
            { value: USER_typeCustomer, text: 'Klient' },
            { value: USER_typeAdmin, text: 'Admin' },
            { value: USER_typeDriver, text: 'Kierowca' }
        ],
        form: {
            userType: null,
            name: null,
            surname: null,
            email: null,
            phone: null
        }
    }),
    mounted(){
    },
    validators: {
        'form.userType': function (value) {
            return Validator.value(value).required();
        },
        'form.name': function (value) {
            return Validator.value(value).required().maxLength(100);
        },
        'form.surname': function (value) {
            return Validator.value(value).required().maxLength(100);
        },
        'form.phone': function (value) {
            if (value)
                return Validator.value(value).maxLength(50);
        },
        'form.email': {
            cache: 'last',
            debounce: 200,
            validator: function (value) {
                let vMessage = Validator.value(value)
                    .required()
                    .minLength(5)
                    .maxLength(255)
                    .email();
                if ( vMessage._messages.length == 0 ) {
                    vMessage = Validator.custom(function () {  
                        if (!Validator.isEmpty(value)) {
                            return this.$store.dispatch('auth/checkEmailIsFree', { email: this.form.email, id: null }  )
                                .catch( ( error ) =>  {
                                    return error;
                                });
                        }
                    }.bind(this) );
                }
                return vMessage;
            }
        },
    },
    methods: {
        beforeSubmit(){
            this.inProgress = true;
            this.$validate().then( this.submit );
        },
        submit(success){
            if (success) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
                axios.post('/admin/user/add', this.form ).then( (result) => {
                    this.$router.push( {name: 'AdminUserPreview',  params: { userId: result.data.userId }} );
                }).catch( () => {
                    this.inProgress = false;
                    this.$bvToast.toast(`Błąd dodawania użytkownika`, {
                        title: 'Błąd',
                        autoHideDelay: 5000,
                        solid: true,
                        variant: 'danger',
                        appendToast: true,
                        toaster: 'b-toaster-top-right'
                    })
                });
            } else {
                this.inProgress = false;
            }
        }
    }
};
</script>